  /* Center text inside each video column */
.embed-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* Add spacing between videos */
.mb-4 {
  margin-bottom: 1rem;
  
}
.yutube-videos{
    color: #007bff;
}
/* Style for the title */
h1 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.mt-5 {
    background-color: #f8f9fa;    

}


