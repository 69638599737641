/* FirstHome.css */
/* Make the carousel container smaller */
.first-home-container {
    max-width: 1000px; /* Adjust the maximum width as needed */
    margin: auto;
  }
  
  /* Style for the title */
  .first-home-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    
  }

  .coursel-imgs {
    color: #007bff;
  }
  
  /* Style for the carousel images */
  .first-home-carousel-item img {
    max-height: 500px; /* Adjust the maximum height of the images */
    object-fit: cover; /* Ensure images cover the entire container */
  }

  .first-home-container{
    background-color: #f8f9fa;
  }