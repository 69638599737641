/* src/components/SignupPage.css */

/* Add any additional custom styles or overrides here */
.card {
  margin-top: 20px;
}

.form-label {
  font-weight: bold;
}

/* Customize the button color */
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Center the form within the card */
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Add margin to the top of the paragraph */
.mt-3 {
  margin-top: 15px;
}

/* Center the paragraph text */
.text-center {
  text-align: center;
}
