.your-paragraph-class {
    transition: transform 0.3s ease-in-out;
  }
  
  .your-paragraph-class:hover {
    transform: translateY(-3px);
  }
  
  a:hover {
    /* Styles for when the link is hovered */
    color: #2fbd2c; /* Change color on hover */
    text-decoration: underline; /* Add underline on hover */
    /* Add other styles as needed */
  }

  .text-primary:hover {
    color: #2fbd2c !important; /* Change color on hover */
    text-decoration: underline !important; /* Add underline on hover */
  }

  .logo-circle {
    width: 200px; /* Set your desired width */
    height: 200px; /* Set your desired height */
    border-radius: 1%; /* Make it circular */
  }
  