/* src/components/HomePage.css */

body, html {
    margin: 0;
    padding: 0;
    
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
  }
  
  .home-container {
    width: 100%;
    margin: 0;
    z-index: 1;
  }

  .hero-section {
    position: relative;
    overflow: hidden;
    text-align: center;
    color: #fff;
  }
  
  .hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/image.jpg');
    background-size: cover;
    background-position: center;
    background-color: black;
    color: black;
    opacity: 0.8; /* Adjust the opacity to your preference */
    z-index: -1;
  }
  .hero-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.1; /* Adjust the opacity to your preference */
    z-index: -1;
  }
  

  .hero-content {
    position: relative;
    
    padding: 100px 20px; /* Adjust the padding to your preference */
  }

  .hero-content h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 1.2em;
    margin-bottom: 40px;
  }
  
  .explore-button {
    display: inline-block;
    padding: 15px 30px;
    margin-top: 30px;
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    border-radius: 8px;
    font-size: 1.2em;
    transition: background-color 0.3s ease;
  }
  
  .explore-button:hover {
    background-color: #0056b3;
  }
  
  .image-section img {
    width: 100%;
    height: auto;
    margin-top: 40px;
    border-radius: 8px;
  }
  
   .contact-section {
    background-color: #f4f4f4;
    padding: 40px;
    text-align: center;
  }
  
  .contact-section h2 {
    color: #333;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .contact-section p {
    color: #555;
    font-size: 1.2em;
  }
  
  .contact-button {
    display: inline-block;
    padding: 15px 30px;
    margin-top: 20px;
    text-decoration: none;
    color: #fff;
    background-color: #28a745;
    border-radius: 8px;
    font-size: 1.2em;
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: #218838;
  }
  
/* ... (rest of your styles) */
/* src/css/HomePage.css */
.general-info-section {
  padding: 80px 0;
  background-color: #f8f9fa; /* Customize the background color */
}

.general-info-section h2 {
  font-size: 2.5em;
  color: #007bff; /* Customize the heading color */
}

.general-info-section p {
  font-size: 1.2em;
  color: #555; /* Customize the text color */
}

/* Customize other styles as needed */

/* Add these styles to your CSS file */

.latest-affiliation-container {
  background-color: #f8f9fa; /* Light gray background color */
  padding: 40px;
  text-align: center;
}

.latest-affiliation-container h2 {
  color: #007bff; /* Blue header color */
  font-size: 2em;
  margin-bottom: 20px;
}

.latest-affiliation-container p {
  color: #333; /* Dark text color */
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* You can add more styles or modify existing styles based on your design requirements */





