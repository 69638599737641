/* src/css/ServicesPage.css */
.service-container {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.service-container:hover {
  transform: translateY(-10px);
}

.service-image {
  width: 100%;
  height: auto;
}

.service-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  text-align: center;
}

.service-content h3 {
  margin-bottom: 10px;
}

.service-content p {
  color: #555;
}

/* src/css/ServicesPage.css */
/* hotels */

.hotels-deals-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  .hotels-deals-container:hover {
    transform: translateY(-10px);
  }
  
  .hotels-deals-image {
    width: 100%;
    height: auto;
  }
  
  .hotels-deals-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .hotels-deals-content:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .hotels-deals-content h3 {
    margin-bottom: 10px;
    color: #0c0c0c;
  }
  
  .hotels-deals-content p {
    color: #555;
  }
  
  .hotels-deals-content ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .hotels-deals-content ul li {
    margin-bottom: 5px;
    color: #555;
  }


/* src/css/ServicesPage.css */
/* Packages */

.vacation-packages-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  .vacation-packages-container:hover {
    transform: translateY(-10px);
  }
  
  .vacation-packages-image {
    width: 100%;
    height: auto;
  }
  
  .vacation-packages-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .vacation-packages-content:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .vacation-packages-content h3 {
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .vacation-packages-content p {
    color: #555;
  }
  
  .vacation-packages-content ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .vacation-packages-content ul li {
    margin-bottom: 5px;
    color: #555;
  }
  