/* General Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa; /* Light gray background */
  color: #343a40; /* Dark text color */
}

.logo a {
  color: #007bff; /* Blue color for the logo */
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.logo-image {
  width: 40px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Adjust margin as needed to separate the logo from text */
}

.logo a:hover {
  color: #2fbd2c;
}

.nav-links {
  display: flex;
  gap: 1rem;
}

.nav-links a {
  color: #007bff; /* Blue color for links */
  text-decoration: none;
  margin: 0 1rem;
  transition: color 0.3s; /* Smooth color transition on hover */
}

.nav-links a:hover {
  color: #2fbd2c; /* Darker blue on hover */
  text-decoration: underline;
}

.auth {
  position: relative;
}

.auth button {
  color: #007bff; /* Blue color for Sign In button */
  background-color: #fff; /* White background for Sign In button */
  border: 1px solid #007bff; /* Blue border for Sign In button */
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition on hover */
}

.auth button:hover {
  background-color: #007bff; /* Blue background on hover */
  color: #fff; /* White text on hover */
}

/* Mobile Menu Styles */
.mobile-menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 0;
  color: #007bff; /* Blue color for the toggle button */
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #007bff; /* Blue color for the bars */
  margin: 5px 0;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #f8f9fa; /* Light gray background for mobile menu */
    z-index: 99;
  }

  .nav-links.show {
    display: flex;
  }

  .mobile-menu-toggle {
    display: flex;
  }
}
